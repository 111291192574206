body {
	margin: unset;
}
html {
	font: 300 1em/1.5 "Noto Sans", "Arial", sans-serif;
}
$background: #FDC835;
.app {
	display: flex;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	justify-content: center;
	align-items: center;
	color: scale-color($background, $lightness: -65%);
	background-color: $background;
	.-status {
		padding: {
			right: 1rem;
			left: 1rem;
		}
		text-align: center;
		font-weight: 700;
		font-size: 2em;
	}
}
.days {
	display: grid;
	grid-template-columns: [name] max-content [sunrise] max-content [sunset] max-content [fill] auto;
	justify-content: unset;
	align-items: unset;
	.day {
		display: contents;
		height: calc(100vh / 7);
		> * {
			display: flex;
			align-items: center;
			background: inherit;
		}
		.day-name,
		.day-times {
			padding-right: 1rem;
		}
		.day-name {
			grid-column: name;
			padding-left: 1rem;
			justify-content: flex-start;
			font-weight: 700;
		}
		.day-times {
			grid-column: sunrise;
			justify-content: flex-end;
		}
		.day-fill {
			grid-column: fill;
		}
		@for $i from 0 to 7 {
			&:nth-child(#{$i + 1}) {
				background-color: scale-color($background, $lightness: -2.5% * $i, $saturation: -5% * $i);
				color: scale-color($background, $lightness: -65% + (-2.5% * $i), $saturation: -5% * $i);
			}
		}
	}
}
